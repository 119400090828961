import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './index.css'

const ModalContext = React.createContext({})
const ModalProvider = ModalContext.Provider
const ModalConsumer = ModalContext.Consumer

const Body = ({ children }) => {
  return (
    <ModalConsumer>
      {({ onClick }) => (
        <div
          className="modal-body"
        // style={{ padding: 0 }}
        >
          <button
            className="close"
            type="button"
            onClick={onClick}
            style={{ border: '1px solid rgba(0,0,0,0.2)', color: "#007991" }}
          >
            &times;
          </button>
          {children}
        </div>
      )}
    </ModalConsumer>
  )
}

const ModalBooking = ({ isShow, handleModalClose, children, id }) => {
  // class show aria-modal="true" else aria-hidden="true"
  // backdrop display none block

  const style = isShow ? { display: 'block' } : { display: 'none' }
  if (isShow)
    return (
      <ModalProvider
        value={{
          onClick: handleModalClose,
        }}
      >
        <div
          role="presentation"
          onKeyPress={() => handleModalClose}
          onClick={() => handleModalClose}
          className={classNames({ 'modal-backdrop fade show': isShow })}

        />
        <div
          id={id}
          className={classNames('modal', 'fade', { show: isShow })}
          role="dialog"
          style={style}
        >
          <div className={classNames('modal-dialog', 'video-container-booking', { show: isShow })}>
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </ModalProvider>
    )
  return null
}

ModalBooking.Body = Body

ModalBooking.propTypes = {
  id: PropTypes.string,
}

ModalBooking.defaultProps = {
  id: '',
}

export default ModalBooking
